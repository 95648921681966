import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { getEvents } from '../Service/Api';
import LazyLoad from 'react-lazyload';
const Events = () => {
  const settings = {
    autoplay: true,
    adaptiveHeight: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventData = await getEvents();
        setData(eventData);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("events_data", data);
  }, [data]);

  const emptyArray = [
    { title: "Event Title", date: "DD/MM/YYYY", time: "00:00", venue: "School", images: "events.jpg", description: "Stay tuned for latest updates" },
    { title: "Event Title", date: "DD/MM/YYYY", time: "00:00", venue: "School", images: "events.jpg", description: "Stay tuned for latest updates" },
    { title: "Event Title", date: "DD/MM/YYYY", time: "00:00", venue: "School", images: "events.jpg", description: "Stay tuned for latest updates" }
  ];

  return (
    <>
      <Slider {...settings}>
        {data?.length > 0 ? data.map((item, index) => (
          <div key={index}>
            <Link to="#" target="_blank" className="event-blk">
              <div className="event-image">
                <LazyLoad>{item.images.length>0 ? (<img src={`https://webapi.entab.info/api/image/${item?.images[0]}`} alt="Event" />):(<img src="https://webapi.entab.info/api/image/SHSJ/public/Images/events.jpg" alt="Event" />)}</LazyLoad>
                
              </div>
              <div className="content">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <p><span>{item.date} | {item.time} | {item.venue}</span></p>
              </div>
            </Link>
          </div>
        )) : emptyArray.map((item, index) => (
          <div key={index}>
            <Link to="#" target="_blank" className="event-blk">
              <div className="event-image">
                <LazyLoad><img src={item.images} alt="Event" /></LazyLoad>
              </div>
              <div className="content">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <p><span>{item.date} | {item.time} | {item.venue}</span></p>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default Events;
