import React, { useEffect, useState } from 'react' 
import Slider from "react-slick";
import { getBirthdays } from '../Service/Api';
import LazyLoad from 'react-lazyload';

const Birthday = () => {
  var settings = {
    autoplay: true,
    adaptiveHeight: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const topperData = await getBirthdays();
      setData(topperData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("events_data", data);
  }, [data]);

  const emptyArray = [
    { name: "Student Name", class: "Class", attachments: "topper.jpg" },   
    { name: "Student Name", class: "Class", attachments: "topper.jpg" },   
    { name: "Student Name", class: "Class", attachments: "topper.jpg" },   
  ];

  return (
    <>
      <Slider {...settings}>
        {data.length > 0 ? data.map((item, index) => (
            <div key={index}>
              <div className="acheiver-blk">
                <div className="acheiver-image"> 
                <LazyLoad>{item.attachments.length>0 ? (<img src={`https://webapi.entab.info/api/image/${item.attachments[0]}`} alt="Sacred Heart Convent School, Jagraon"/>):(<img src="https://webapi.entab.info/api/image/SHSJ/public/Images/dummy.jpg" alt="Sacred Heart Convent School, Jagraon"/>)}</LazyLoad>
                  
                </div>
                <div className="content">
                  <h3>{item.name}</h3>
                  <p>{item.class}</p>
                </div> 
                <LazyLoad><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/balloons.gif" className="balon-gif" alt="Sacred Heart Convent School, Jagraon" /> </LazyLoad>
              </div>
            </div>
          ))
        :emptyArray.map((data, index) => (
          <div key={index}>
            <div className="acheiver-blk">
              <div className="acheiver-image"> 
                <LazyLoad><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/dummy.jpg" alt="Sacred Heart Convent School, Jagraon"/></LazyLoad>
              </div>
              <div className="content">
                <h3>{data.name}</h3>
                <p>{data.class}</p>
              </div> 
              <div className="percentage">
                {data.division}
              </div>
              <LazyLoad><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/balloons.gif" className="balon-gif" alt="Sacred Heart Convent School, Jagraon" /> </LazyLoad>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
}

export default Birthday;
