import { useEffect, useState } from "react"; 
import { useNavigate, Link } from "react-router-dom"
import { getCircular } from "../Service/Api";
import LazyLoad from "react-lazyload";
const Circular = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const Year = `${currentYear}`;
        setSelectedYear(Year);
        console.log("get current Year", Year);
     }, []);

    useEffect(() => {
        const fetchData = async () => {
            const circularData = await getCircular();
            console.log("circular data>>>",circularData); 
            setData(circularData);
        };
        fetchData();
    }, []);
  
    useEffect(() => {
        if(!data) {
            return;
        }
        console.log(selectedMonth, selectedYear, searchQuery);
        let filteredData = data;
        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date && item.date.trim() !== "") {
                    const year = new Date(item.date).getFullYear();
                    const month = new Date(item.date).getMonth() + 1;
                    return parseInt(selectedYear) === year && (selectedMonth === "All" || parseInt(selectedMonth) === month);
                } else {
                    return false;
                }
            });
        }
        if (selectedMonth !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date && item.date.trim() !== "") {
                    const month = new Date(item.date).getMonth() + 1;
                    return parseInt(selectedMonth) === month;
                } else {
                    return false;
                }
            });
        }
        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
        setFilteredData(filteredData);
        const datas = filteredData.filter((div) => div.category === "Circular");
        setFilteredData(datas);
    }, [selectedYear, selectedMonth, searchQuery, data]);

    useEffect(() => {
        console.log('filterData', filterData)
    }, [filterData]);

    const handleModal = (id) => {
      navigate(`/SubGallery?id=${id}`);
    };
     

  return (
      <>
      <div className="innerslide">
      <ul className="breadcrumb">
      <li><Link to="/home"> Home</Link> </li>
         <li> Information</li>
         <li> Circular </li>
        </ul>
        </div>
        <div className="innersec">
        <div className="container">
                <div className="row">
                <div className="col-lg-12">
                        <h1> Circular </h1>
                </div>
                </div>
                <div className="row tabs-dynamic">
                        <div className="col-md-3">
                            <div className="count-val">
                                <p>Total Count: {filterData.length}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="month-selection">
                                <select 
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    className="yearSelection"
                                >
                                    <option value={"All"}>All</option>
                                    {yearRanges.map((yr) => (
                                        <option key={yr} value={yr}>{yr}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="searchBox">
                                <input
                                    type="text"
                                    id="myInput"
                                    name="name"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder="Search Here.."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {filterData.length > 0 ? (
                            filterData.map((item, index) => ( 
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 videosearch" key={index}>
                                    <div className="video-gallery">
                                        <Link to={`https://webapi.entab.info/api/image?url=${item?.attachments[0]}`} target="_blank">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/circular.png" alt="Sacred Heart School, Jagraon" /></LazyLoad>
                                            <h3>{item.title}</h3>
                                        </Link>
                                    </div>
                                </div>
                               
                            ))
                        ) : (
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 videosearch">
                                    <div className="video-gallery">
                                        <Link to="/" target="_blank">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/circular.png" alt="Sacred Heart School, Jagraon" /></LazyLoad>
                                            <h3>Circular Title</h3>
                                        </Link>
                                    </div>
                                </div>
                        )}
                    </div> 
                {/* <div className="row"> 
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12"> 
                <div className="video-gallery"> 
                    <Link to="#" target="_blank">
                    <img src="src="https://webapi.entab.info/api/image/SHSJ/public/Images/pdf.png" alt="Sacred Heart Convent School, Jagraon" /> 
                    <div> 
                        <h3>Title</h3> 
                        <p>27.02.2024</p> 
                    </div> 
                    </Link>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12"> 
                <div className="video-gallery"> 
                    <Link to="#" target="_blank">
                    <img src="src="https://webapi.entab.info/api/image/SHSJ/public/Images/pdf.png" alt="Sacred Heart Convent School, Jagraon" /> 
                    <div> 
                        <h3>Title</h3> 
                        <p>27.02.2024</p> 
                    </div> 
                    </Link>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12"> 
                <div className="video-gallery"> 
                    <Link to="#" target="_blank">
                    <img src="src="https://webapi.entab.info/api/image/SHSJ/public/Images/pdf.png" alt="Sacred Heart Convent School, Jagraon" /> 
                    <div> 
                        <h3>Title</h3> 
                        <p>27.02.2024</p> 
                    </div> 
                    </Link>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12"> 
                <div className="video-gallery"> 
                    <Link to="#" target="_blank">
                    <img src="src="https://webapi.entab.info/api/image/SHSJ/public/Images/pdf.png" alt="Sacred Heart Convent School, Jagraon" /> 
                    <div> 
                        <h3>Title</h3> 
                        <p>27.02.2024</p> 
                    </div> 
                    </Link>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12"> 
                <div className="video-gallery"> 
                    <Link to="#" target="_blank">
                    <img src="src={pdf} alt="Sacred Heart Convent School, Jagraon" /> 
                    <div> 
                        <h3>Title</h3> 
                        <p>27.02.2024</p> 
                    </div> 
                    </Link>
                    </div>
                </div>
                </div> */}
        </div>
        </div>
       
      </>
  )
}

export default Circular
