import React from 'react'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'
const Infrastructure = () => {
  return (
    <>
      <div className="innerslide">
        <ul class="breadcrumb">
          <li><Link to="/home"> Home</Link> </li>
          <li>Infrastructure</li>
          <li> School Infrastructure </li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container-fluid conductbox Infrastructure">
          <h1> School Infrastructure </h1>
          <div className='row'>
            <div className="col-lg-12">
              <div className="table-responsive maintable">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>    TOTAL NUMBER OF SITES OF SCHOOL </td>
                      <td>    1   </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF PLAYGROUNDS IN SCHOOL   </td>
                      <td>    1   </td>

                    </tr>
                    <tr>    <td>    NUMBER OF BUILDING BLOCKS OF SCHOOL </td>
                      <td>    2   </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF CLASS ROOMS </td>
                      <td>    84  </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF MEDIUM-SIZED ROOMS  </td>
                      <td>    5   </td>

                    </tr>
                    <tr>    <td>    NUMBER OF AUDITORIUMS   </td>
                      <td>    1   </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF DIGITAL CLASSROOMS  </td>
                      <td>    60  </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF LIBRARIES   </td>
                      <td>    1   </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF FEMALE REST ROOMS   </td>
                      <td>    8   </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF CANTEENS    </td>
                      <td>    1   </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF TOILETS </td>
                      <td>    150 </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF BOYS' TOILETS   </td>
                      <td>    60  </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF WASHROOMS FOR FEMALE STAFF  </td>
                      <td>    15  </td>

                    </tr>
                    <tr>    <td>    TOTAL AREA OF SCHOOL IN SQUARE METRES   </td>
                      <td>    24205   </td>

                    </tr>
                    <tr>    <td>    TOTAL AREA OF PLAYGROUND IN SQUARE METRES   </td>
                      <td>    21442   </td>

                    </tr>
                    <tr>    <td>    DOES THE SCHOOL HAS A BOUNDARY WALL </td>
                      <td>    YES </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF SMALL-SIZED ROOM    </td>
                      <td>    4   </td>

                    </tr>
                    <tr>    <td>    NUMBER OF LARGE-SIZED ROOMS </td>
                      <td>    2   </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF ACTIVITY ROOMS  </td>
                      <td>    6   </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF LIFTS/ELEVATORS </td>
                      <td>    0   </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF LABORATORIES    </td>
                      <td>    9   </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF MALE REST ROOMS </td>
                      <td>    1   </td>

                    </tr>
                    <tr>    <td>    NUMBER OF STAFF CANTEENS    </td>
                      <td>    1   </td>

                    </tr>
                    <tr>    <td>    NUMBER OF GIRLS' TOILETS    </td>
                      <td>    60  </td>

                    </tr>
                    <tr>    <td>    NUMBER OF TOILETS FOR DIFFERENTLY ABLED PERSONS </td>
                      <td>    2   </td>

                    </tr>
                    <tr>    <td>    NUMBER OF WASHROOMS FOR MALE STAFF  </td>
                      <td>    3   </td>

                    </tr>
                    <tr>    <td>    NUMBER OF WATER PURIFIERS/ROs AVAILABLE IN SCHOOL   </td>
                      <td>    17  </td>

                    </tr>
                    <tr>    <td>    DOES THE SCHOOL HAVE SPORTS FACILITY?   </td>
                      <td>    YES </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF COMPUTERS (ALL COMPUTER LABS COMBINED)  </td>
                      <td>    109 </td>

                    </tr>
                    <tr>    <td>    TOTAL BOOKS AVAILABLE IN THE SCHOOL LIBRARY </td>
                      <td>    11150   </td>

                    </tr>
                    <tr>    <td>    PERIODICALS IN THE SCHOOL LIBRARY   </td>
                      <td>    10  </td>

                    </tr>
                    <tr>    <td>    DAILIES IN THE SCHOOL LIBRARY   </td>
                      <td>    5   </td>

                    </tr>
                    <tr>    <td>    REFERENCE BOOKS IN THE SCHOOL LIBRARY   </td>
                      <td>    800 </td>

                    </tr>
                    <tr>    <td>    LIBRARY RESOURCES   </td>
                      <td>    11150   </td>

                    </tr>
                    <tr>    <td>    LIBRARY SIZE (IN SQ. METERS)    </td>
                      <td>    15  </td>

                    </tr>
                    <tr>    <td>    COMPUTER LAB SIZE (IN SQ. METERS)   </td>
                      <td>    11  </td>

                    </tr>
                    <tr>    <td>    COMPOSITE SCIENCE LAB SIZE (IN SQ. METERS)  </td>
                      <td>    11  </td>

                    </tr>
                    <tr>    <td>    CHEMISTRY LAB SIZE (IN SQ. METERS)  </td>
                      <td>    11  </td>

                    </tr>
                    <tr>    <td>    PHYSICS LAB SIZE (IN SQ. METERS)    </td>
                      <td>    11  </td>

                    </tr>
                    <tr>    <td>    HOW MANY ROOMS BETWEEN 400 to 500 SQ FT </td>
                      <td>    84  </td>

                    </tr>
                    <tr>    <td>    TOTAL NUMBER OF LABORATORY  </td>
                      <td>    9   </td>

                    </tr>
                    <tr>    <td>    BIOLOGY LAB SIZE (IN SQ. METERS)    </td>
                      <td>    11  </td>

                    </tr>
                    <tr>    <td>    MATHEMATICS LAB SIZE (IN SQ. METERS)    </td>
                      <td>    11  </td>

                    </tr>
                    <tr>    <td>    HOW MANY ROOMS LESS THAN 400 SQ FT  </td>
                      <td>    6   </td>

                    </tr>
                    <tr>    <td>    HOW MANY ROOMS GREATER THAN 500 SQ FT   </td>
                      <td>    4   </td>

                    </tr>
                  </tbody></table></div>
            </div>
          </div>
          <br />
          
          <div className='row'>
            <div className='col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6 col-12'>
              <LazyLoad><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/Classroom.jpg" className="img-thumbnail mx-auto d-block" alt="The Sacred Heart Convent" /></LazyLoad>
              <h4>Classroom</h4>
            </div>

            <div className='col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6 col-12'>
              <LazyLoad><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/Examination-Room.jpg" className="img-thumbnail mx-auto d-block" alt="The Sacred Heart Convent" /></LazyLoad>
              <h4>Examination Room</h4>
            </div>

            <div className='col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6 col-12'>
              <LazyLoad><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/MedicalRoom.jpg" className="img-thumbnail mx-auto d-block" alt="The Sacred Heart Convent" /></LazyLoad>
              <h4>Medical Room</h4>
            </div>

            <div className='col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6 col-12'>
              <LazyLoad><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/Musicroom.jpg" className="img-thumbnail mx-auto d-block" alt="The Sacred Heart Convent" /></LazyLoad>
              <h4>Music Room</h4>
            </div>

            <div className='col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6 col-12'>
              <LazyLoad><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/Staffroom.jpg" className="img-thumbnail mx-auto d-block" alt="The Sacred Heart Convent" /></LazyLoad>
              <h4>Staff Room</h4>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Infrastructure
