import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getVideo } from '../Service/Api';
import LazyLoad from 'react-lazyload';

const VideoGallery = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [year, setYear] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const galleryData = await getVideo();
      setData(galleryData);
      setFilteredData(galleryData);
      const uniqueYears = Array.from(new Set(galleryData.map(item => new Date(item.date).getFullYear()))).sort((a, b) => b - a);
      setYear(uniqueYears);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const filter = data.filter(item => {
      const itemYear = new Date(item.date).getFullYear();
      const matchesYear = selectedYear === "All" || itemYear === parseInt(selectedYear);
      const matchesSearch = item.title.toLowerCase().includes(searchQuery.toLowerCase());
      return matchesYear && matchesSearch;
    });
    setFilteredData(filter);
  }, [selectedYear, searchQuery, data]);

  // Sort the filtered data before rendering
  const sortedItems = filterData.sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <>
      <div className="innerslide">
        <ul className="breadcrumb">
          <li><Link to="/home"> Home</Link> </li>
          <li> Gallery </li>
          <li> Video Gallery </li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1> Video Gallery </h1>
            </div>
          </div>
          <div className="row tabs-dynamic">
            <div className="col-md-4">
              <div className="count-val">
                <p>Total Count: {filterData.length}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="month-selection">
                <select id="yearSelection" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
                  <option value="All">All Years</option>
                  {year.map((yr, index) => (
                    <option key={index} value={yr}>{yr}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="searchBox">
                <input
                  type="text"
                  id="myInput"
                  name="name"
                  autoComplete="off"
                  placeholder="Search Here.."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="clr"></div>
          <div id="showTwo" className="myDiv1 selected_year">
            <div className="row">
              {sortedItems && sortedItems.length > 0 ? (
                sortedItems.map((item, index) => (
                  <div key={index} className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                    <div className="video-gallery">
                      <Link to={item.videoUrl} target="_blank">
                        <div>
                          <div className="videogallery">
                         <LazyLoad>   <img
                              src={
                                item.attachments && item.attachments.length > 0
                                  ? `https://webapi.entab.info/api/image/${item.attachments[0]}`
                                  : "https://webapi.entab.info/api/image/SHSJ/public/Images/videogallery.jpg"
                              }
                              alt={item.title}
                              className="img-fluid mx-auto d-block"
                            /></LazyLoad>
                              <div className="overlay"></div>
                            <i className="bi bi-play play-icon"></i>

                          </div>
                          <h3 className=' text-center'>{item.title}</h3>
                          <p className=' text-center'>{new Date(item.date).toLocaleDateString('en-GB')}</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="video-gallery">
                    <Link to="#" target="_blank">
                      <div>
                        <div className="videogallery">
                          <LazyLoad><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/innersld.jpg" alt="shcsjagraon" className="img-fluid" /></LazyLoad>
                          <i className="bi bi-play-circle-fill play-icon"></i>
                        </div>
                        <h3>Video Title</h3>
                        <p>DD.MM.YYYY</p>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoGallery;
