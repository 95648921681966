import Home from './Pages/Home';
import Footer from './Components/Footer';
import './Assets/css/Style.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './Components/Header';
import OurSchool from './Pages/OurSchool';
import OurMotto from './Pages/OurMotto';
import PrincipalMessage from './Pages/PrincipalMessage';
import DirectorMessage from './Pages/DirectorMessage'
import OurHistory from './Pages/OurHistory'
import OurDedicatedTeachers from './Pages/OurDedicatedTeachers'
import Admission from './Pages/Admission'
import Uniform from './Pages/Uniform'
import Curriculum from './Pages/Curriculum'
import RulesRegulations from './Pages/RulesRegulations'
import Calendar from './Pages/Calendar'
import Evaluation from './Pages/Evaluation'
import Conduct from './Pages/Conduct'
import MandatoryDisclosure from './Pages/MandatoryDisclosure'
import ContactUs from './Pages/ContactUs'
import Infrastructure from './Pages/Infrastructure'
import Tc from './Pages/Tc'
import VideoGallery from './Pages/VideoGallery'
import HolidayHomework from './Pages/HolidayHomework'
import Circular from './Pages/Circular'
import ErpSoftware from './Pages/ErpSoftware'
import Pphelp from './Pages/Pphelp'
import Alumni from './Pages/Alumni';
import News from './Pages/News'
import Events from './Pages/Events'
import PhotoGallery from './Pages/PhotoGallery'
import SubGallery from './Pages/SubGallery'
import 'lightbox.js-react/dist/index.css'
import ToppersICSE from './Pages/ToppersICSE';
import ToppersISC from './Pages/ToppersISC';
import Achievements from './Pages/Achievements';
import EBook from './Pages/EBook';


function App() {
  return (
    <BrowserRouter>
      <Header></Header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="OurSchool" element={<OurSchool />} />
        <Route path="OurMotto" element={<OurMotto />} />
        <Route path="PrincipalMessage" element={<PrincipalMessage />} />
        <Route path="DirectorMessage" element={<DirectorMessage />} />
        <Route path="OurHistory" element={<OurHistory />} />
        <Route path="OurDedicatedTeachers" element={<OurDedicatedTeachers />} />
        <Route path="Admission" element={<Admission />} />
        <Route path="Uniform" element={<Uniform />} />
        <Route path="Curriculum" element={<Curriculum />} />
        <Route path="RulesRegulations" element={<RulesRegulations />} />
        <Route path="Evaluation" element={<Evaluation />} />
        <Route path="Calendar" element={<Calendar />} />
        <Route path="Conduct" element={<Conduct />} />
        <Route path="Circular" element={<Circular />} />
        <Route path="HolidayHomework" element={<HolidayHomework />} />
        <Route path="TC" element={<Tc />} />
        <Route path="Infrastructure" element={<Infrastructure />} />
        <Route path="VideoGallery" element={<VideoGallery />} />
        <Route path="ContactUs" element={<ContactUs />} />
        <Route path="MandatoryDisclosure" element={<MandatoryDisclosure />} />
        <Route path="ErpSoftware" element={<ErpSoftware />} />
        <Route path="Pphelp" element={<Pphelp />} />
        <Route path="Alumni" element={<Alumni />} />
        <Route path="News" element={<News />} />
        <Route path="Events" element={<Events />} />
        <Route path="PhotoGallery" element={<PhotoGallery />} />
        <Route path="SubGallery" element={<SubGallery />} />
        <Route path="ToppersICSE" element={<ToppersICSE />} />
        <Route path="ToppersISC" element={<ToppersISC />} />
        <Route path="Achievements" element={<Achievements />} />
        <Route path="EBook" element={<EBook />} />

        

      </Routes>
      <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
