import React, { useEffect, useState } from 'react';
import { getGallery } from '../Service/Api';
import LazyLoad from 'react-lazyload';

const Gallery = () => {    
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const datas = await getGallery();
      setData(datas);
    };
    getData();
  }, []);

  const emptyArray = [
    { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SHSJ/public/Images/aboutimg.jpg"},   
    { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SHSJ/public/Images/aboutimg.jpg"},   
    { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SHSJ/public/Images/aboutimg.jpg"},   
    { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SHSJ/public/Images/aboutimg.jpg"},   
    { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SHSJ/public/Images/aboutimg.jpg"},   
    { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SHSJ/public/Images/aboutimg.jpg"},   
  ];

  return (
    <>
      <div className="gallery-blk">
        {data?.length > 0 ? (
          data.map((item, index) => (    
                index < 3 && 
            <div className="gallery-img" key={index}>
              <LazyLoad> <img src={`https://webapi.entab.info/api/image/${item.attachments[0]}`} alt="Sacred Heart Convent School, Jagraon" /></LazyLoad>
              <div className="title">
                {item.title}
              </div>
            </div>
          ))
        ) : (
          emptyArray.map((data, index) => (
            <div className="gallery-img" key={index}>
   <LazyLoad><img src={data.attachments} alt="Sacred Heart Convent School, Jagraon" /></LazyLoad>
            <div className="title">
              {data.title}
            </div>
          </div>
          ))
        )}
      </div>
    </>
  );
}

export default Gallery;
