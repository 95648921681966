import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getGalleryInner } from "../Service/Api";
import LazyLoad from 'react-lazyload';
function Achievements() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const convertDateToDDMMYYYY = (isoString) => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    useEffect(() => {
        const fetchData = async () => {
            const galleryData1 = await getGalleryInner();
            const galleryData = galleryData1.filter(item => item.category === 'Achievements');

            // Convert date to DD MM YYYY format
            const formattedGalleryData = galleryData.map(item => ({
                ...item,
                formattedDate: item.date ? convertDateToDDMMYYYY(item.date) : null,
            }));

            setData(formattedGalleryData);
        };

        fetchData();
    }, []);

    const handleModal = id => {
        navigate(`/SubGallery?id=${id}`);
    };

    const emptyArray = [
        {
            description: "“Achivements",
            attachments: ["director.jpg"]
        }
    ];
    return (
        <>
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/home"> Home</Link> </li>
                    <li>Gallery</li>
                    <li> Achievements </li>

                </ul>
            </div>
            <div className="innersec">
                <div className='container'>
                    <div class="row">
                        <div class="col-md-12">
                            <h1>Our Achievements</h1>
                            <div className="accordion" id="AccordionAchievement">
                                {data.length > 0 ? (
                                    data.map((item, index) => (
                                        <div className="accordion-item" key={index}>
                                            <h2 className="accordion-header" id={`heading-${index}`}>
                                                <button
                                                    className={`accordion-button ${index === 0 ? '' : 'collapsed'}`}
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#collapse-${index}`}
                                                    aria-expanded={index === 0 ? 'true' : 'false'}
                                                    aria-controls={`collapse-${index}`}
                                                >
                                                    {item.title}
                                                </button>
                                            </h2>
                                            <div
                                                id={`collapse-${index}`}
                                                className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                                aria-labelledby={`heading-${index}`}
                                                data-bs-parent="#AccordionAchievement"
                                            >
                                                <div className="accordion-body">
                                                    <div className="achievementdesc">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div id={`carousel-${index}`} className="carousel achievementimg  slide" data-bs-ride="carousel">
                                                                    <div className="carousel-inner">
                                                                        {item.attachments.length > 0 ? (
                                                                            item.attachments.map((attachment, imgIndex) => (
                                                                                <div
                                                                                    className={`carousel-item ${imgIndex === 0 ? 'active' : ''}`}
                                                                                    key={imgIndex}
                                                                                >
                                                                              <LazyLoad>      <img
                                                                                        src={`https://webapi.entab.info/api/image/${attachment}`}
                                                                                        className="d-block w-100"
                                                                                        alt="Achievement Image"
                                                                                    /></LazyLoad>
                                                                                </div>
                                                                            ))
                                                                        ) : (
                                                                            <div className="carousel-item active">
                                                                               <LazyLoad> <img
                                                                                    src="https://webapi.entab.info/api/image/SHSJ/public/Images/Achievements.jpg"
                                                                                    className="d-block w-100"
                                                                                    alt="Default Achievement Image"
                                                                                /></LazyLoad>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <button
                                                                        className="carousel-control-prev"
                                                                        type="button"
                                                                        data-bs-target={`#carousel-${index}`}
                                                                        data-bs-slide="prev"
                                                                    >
                                                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                        <span className="visually-hidden">Previous</span>
                                                                    </button>
                                                                    <button
                                                                        className="carousel-control-next"
                                                                        type="button"
                                                                        data-bs-target={`#carousel-${index}`}
                                                                        data-bs-slide="next"
                                                                    >
                                                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                        <span className="visually-hidden">Next</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8">
                                                                <div className="achievementpgh">
                                                                    <p>{item.description}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading-empty">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse-empty"
                                                aria-expanded="false"
                                                aria-controls="collapse-empty"
                                            >
                                                Achievement Title
                                            </button>
                                        </h2>
                                        <div
                                            id="collapse-empty"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="heading-empty"
                                            data-bs-parent="#AccordionAchievement"
                                        >
                                            <div className="accordion-body">
                                                {/* Default Content */}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Achievements