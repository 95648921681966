import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { postTransferCertificate } from "../Service/Api";
const Tc = () => {
  const navigate = useNavigate();
  const [admission, setAdmission] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const handleAdmissionNoChange = (event) => {
    setAdmission(event.target.value);
    setError(""); // Clear error on user input
  };

  const handleFullNameChange = (event) => {
    setName(event.target.value);
    setError(""); // Clear error on user input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !admission) {
      setError("Please fill in all fields");
      return;
    }

    try {
      const response = await postTransferCertificate(name, admission);
      if (response) {
        console.log(response.data);
        // Potentially navigate or show success message
        fetch(`https://webapi.entab.info/api/image/${response.data}`)
          .then(response => response.blob())  // Assuming the data is in a blob format; adjust if different
          .then(blob => {
            // Create a link element, use it to download the blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'tc.jpg';  // Set the file name and extension
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            // alert('Your download has started.');  // Optional: Notify the user
          })
          .catch(() => alert('Failed to download data.'));  // Error handling
      }
    } catch (error) {
      console.error("Failed to post data", error);
      setError("Failed to process your request. Please try again later.");
    }
  };

  return (
    <>
      <div className="innerslide">
        <ul className="breadcrumb">
          <li>
            <li>
              <Link to="/home"> Home</Link>{" "}
            </li>
          </li>
          <li> Information</li>
          <li> Transfer Certificate </li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1> Transfer Certificate </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tcblock">
                <div className="tc">
                  <div>
                    <form onSubmit={handleSubmit} className="tc" data-aos="fade-left" data-aos-duration="2000">
                      <div className="text_box_sec form-group"> <input className="form-control" id="name" value={name} onChange={handleFullNameChange} type="text" placeholder="Name" /></div>
                      <div className="text_box_sec form-group"> <input className="form-control" id="admission" value={admission} onChange={handleAdmissionNoChange} type="text" placeholder="Admission Number" /></div>
                      <div className="text_box_sec form-group"><input type="submit" value="VIEW DETAILS" /></div>
                      {error && <p className="error">{error}</p>}
                    </form>
                  </div>
                </div>
                <iframe
                  src="
https://webapi.entab.info/api/image/SHSJ/public/pdf/TC-sample.pdf#toolbar=0" width="100%"
                  height="500"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tc;
