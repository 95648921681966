import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import { getPrincipalMessage } from '../Service/Api';
import LazyLoad from 'react-lazyload';

const MessageOne = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const messages = await getPrincipalMessage();
      setData(messages);
    };
    fetchData();
  }, []);

  console.log(data)

  const emptyArray = [
    // { description: "“Educating the mind without educating the heart is no education at all.” Sacred Heart has been educating boys and girls since 1990; though much has changed over the years our mission remains the same. Sacred Heart is committed to developing students of high academic achievement, intellectual curiosity, and strong moral character. As a result, year after year Sacred Heart sends our respectful, responsible and compassionate young adults, who are well prepared to tackle the challenges that lay ahead. Since arriving at Sacred Heart in 2016, I have had the opportunity to observe this unique institution up close. I have seen its commitment to academic excellence, its dynamic student body, and incredibly committed faculty and a loving community where the students and families are proud and supportive of one another. As I walk down the hallways of the school every day, I can hear the chatter of eager minds; the shouts of excitement from the victorious athletes, the thumb of dancers feed and the sound of melodious voices harmonizing the perpetual energy, movement and enthusiasm permeate the atmosphere. Ours is a school with difference. We value creativity and innovation and strive to nurture them in our students. Sacred Heartians are groomed to become confident, articulate and enlightened young citizens ready to step into the global society with dignity, honour and integrity. Aristotle once said “Educating the mind without educating the heart is no education at all”. Even as we impart education to match the advancement in technology and globalization, we march our children ahead with Sacred Heart ethos of moral values and principles. We Endeavour constantly to instill these qualities in our children. We pride ourselves to help them grow and develop into sensitive, responsible God loving citizens of the future. May the Sacred Heart of Jesus bless us abundantly in all our endeavourers.", attachments: "director.jpg" },   
  ];

  return (
    <>
      {data?.length > 0 ? data?.filter((div) => div.category === "Director").map((item, index) => (                
        <div key={index} className='row'>
          <div className="col-md-6 padding-0  arrow-img">
            <LazyLoad><img src={`https://webapi.entab.info/api/image/${item.attachments[0]}`} className="img-fluid" alt="Sacred Heart Convent School, Jagraon" /></LazyLoad>
            <Link to="/DirectorMessage">
              <div className="arrow">
                <LazyLoad><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/arrow.png" alt="Sacred Heart Convent School, Jagraon" /></LazyLoad>
              </div>
            </Link>
          </div>
          <div className="col-md-6 padding-0">
            <div className="message-content">
              <h3>Director's Message</h3>
              <p>{item?.message}</p>
            </div>
          </div>
        </div>
      ))
      :emptyArray.map((data, index) => (
        <div key={index} className='row'>
          <div className="col-md-6 padding-0  arrow-img">
            <LazyLoad><img src={data.attachments} className="img-fluid" alt="Sacred Heart Convent School, Jagraon" /></LazyLoad>
            <Link to="/DirectorMessage">
              <div className="arrow">
                <LazyLoad><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/arrow.png" alt="Sacred Heart Convent School, Jagraon" /></LazyLoad>
              </div>
            </Link>
          </div>
          <div className="col-md-6 padding-0">
            <div className="message-content">
              <h3>Director's Message</h3>
              <p>{data.description}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default MessageOne;
