import React, { useEffect, useState } from 'react' 
import { getTopper } from "../Service/Api";
import Slider from "react-slick";
import LazyLoad from 'react-lazyload';

const HomeToppers = () => {
  var settings = {
    autoplay: true,
    adaptiveHeight: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const topperData = await getTopper();
      setData(topperData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("events_data", data);
  }, [data]);

  const emptyArray = [
    { name: "Student Name", stream: "Stream", division: "100%", attachments: "topper.jpg" },   
    { name: "Student Name", stream: "Stream", division: "100%", attachments: "topper.jpg" },   
    { name: "Student Name", stream: "Stream", division: "100%", attachments: "topper.jpg" },   
  ];
  return (
    <>
      <Slider {...settings}>
        {data?.length > 0 ? data?.filter((div) => div.class === "XII").map((item, index) => (
          <div key={index}>
            <div className="acheiver-blk">
              <div className="acheiver-image"> 
                <LazyLoad><img src={`https://webapi.entab.info/api/image/${item.attachments}`} alt="Sacred Heart Convent School, Jagraon"/></LazyLoad>
              </div>
              <div className="content">
                <h3>{item?.name}</h3>
                <p>{item?.stream}</p>
              </div> 
              <div className="percentage">
                {item?.division}
              </div>
              <LazyLoad><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/like.gif" className="balon-gif" alt="Sacred Heart Convent School, Jagraon" /> </LazyLoad>
            </div>
          </div>
        ))        
        :emptyArray.map((data, index) => (
          <div key={index}>
            <div className="acheiver-blk">
              <div className="acheiver-image"> 
                <LazyLoad><img src={data.attachments} alt="Sacred Heart Convent School, Jagraon"/></LazyLoad>
              </div>
              <div className="content">
                <h3>{data.name}</h3>
                <p>{data.stream}</p>
              </div> 
              <div className="percentage">
                {data.division}
              </div>
              <LazyLoad><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/like.gif" className="balon-gif" alt="Sacred Heart Convent School, Jagraon" /> </LazyLoad>
            </div>
          </div>
        ))}

      </Slider>
    </>
  );
}

export default HomeToppers;
