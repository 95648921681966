import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCircular } from "../Service/Api";
import LazyLoad from "react-lazyload";

function EBook() {
    const [data, setData] = useState([]); // Raw data from API
    const [filteredData, setFilteredData] = useState([]); // Filtered data

    // Fetch data from API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const circularData = await getCircular();
                console.log("Fetched Circular Data:", circularData);
                setData(circularData);
            } catch (error) {
                console.error("Error fetching circular data:", error);
            }
        };
        fetchData();
    }, []);

    // Filter eBooks
    useEffect(() => {
        const filtered = data.filter((item) => item.category === "eBook");
        setFilteredData(filtered);
        console.log("Filtered eBook Data:", filtered);
    }, [data]);

    // Helper function to format the date
    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <div>
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li>
                        <Link to="/home">Home</Link>
                    </li>
                    <li>About Us</li>
                    <li>E-Book</li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>E-Book</h1>
                        </div>
                    </div>

                    <div className="row g-4">
                        {filteredData.length > 0 ? (
                            filteredData.map((item, index) => (
                                <div key={index} className="col-lg-3 col-md-6">
                                    <div className="goal-box">
                                        <Link to={`https://webapi.entab.info/api/image?url=${item?.attachments[0]}`} target="_blank">
                                            <div className="Goal">
                                                <LazyLoad><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/ebook.png" alt="Sacred Heart School, Jagraon" className="img-fluid mx-auto d-block" /></LazyLoad>
                                            </div>
                                            <h4>{item.title}</h4>
                                            <h5>{formatDate(item.date)}</h5>
                                        </Link>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="col-lg-3 col-md-6">
                                <div className="goal-box">
                                    <a href="" rel="noopener noreferrer">
                                        <div className="Goal">
                                            <LazyLoad><img
                                                src="https://webapi.entab.info/api/image/SHSJ/public/Images/ebook.png"
                                                alt="Placeholder Image"
                                                className="img-fluid mx-auto d-block"
                                            /></LazyLoad>
                                        </div>
                                        <h4>E-Book Title</h4>
                                        <h5>MM, YYYy</h5>
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EBook;
